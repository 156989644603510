import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Space, Typography } from 'antd';
import { ModuleTopHeading, MyInput } from '../../components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Text } = Typography

const GenralSettingPage = () => {
    const [form] = Form.useForm();
    

    return (
        <div>
            <Card className="shadow-c radius-12 border0">
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="mb-3">
                                <ModuleTopHeading name="General Settings" />
                            </div>
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="commission"
                                label="Commission"
                                placeholder="30"
                                size='large'
                                value={form.getFieldValue("commission") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="inactiveClient"
                                label="Inactive Client (days)"
                                placeholder="60"
                                size='large'
                                value={form.getFieldValue("inactiveClient") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="helplineCustomer"
                                label="Helpline Number for Customers"
                                placeholder="+966509736323"
                                size='large'
                                value={form.getFieldValue("helplineCustomer") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="helplineSalon"
                                label="Helpline Number for Salons"
                                placeholder="+966509736323"
                                size='large'
                                value={form.getFieldValue("helplineSalon") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col span={24}>
                            <Space className="w-100 right">
                                <Button 
                                    className="btncancel"
                                >
                                    Cancel
                                </Button>
                                <Button  
                                    block
                                    className='btnsave'
                                    type="primary"
                                    htmlType="submit"
                                    onClick={()=> form.submit()}
                                >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
};

export { GenralSettingPage };
