import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewReservation = ({ setDeleteItem, setViewOnly, setViewDrawer, setDocItem, setDocName }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:80
        },
        {
            title: 'Salon Name',
            dataIndex: 'salonName',
            sorter: (a,b) => a.salonName.localeCompare(b.salonName),
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            sorter: (a,b) => a.customerName.localeCompare(b.customerName),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: (a,b) => a.amount.localeCompare(b.amount),
        },
        {
            title: 'Payment',
            dataIndex: 'payment',
            sorter: (a,b) => a.payment.localeCompare(b.payment),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Completed</Text>
                        </Space>
                    ) : status === 2 ? (
                        <Space>
                            <img src="/assets/icons/inprogress.png" width={'8px'} alt="" />
                            <Text className='text-progress' style={{transform:"translateY(-2px)",display:'block'}}>In Progress</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Cancelled</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 'Reservation Date',
            dataIndex: 'reserveDate',
            sorter: (a,b) => a.reserveDate.localeCompare(b.reserveDate),
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 130,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setViewDrawer(true); setViewOnly({name: row?.customerName, date: row?.reserveDate})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Pdf'
                        shape="circle"
                        icon={<Image src='/assets/icons/pdf.png' width={17} preview={false} />}
                        onClick={() => {setDocItem(true);setDocName({name: row?.salonName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.customerName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            salonName: 'Hair Flow',
            customerName: 'Sajid Hussain',
            amount: 'SAR 15',
            payment: 'Cash',
            reserveDate: '11:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#12',
            salonName: 'Hair Artistry',
            customerName: 'Sameh Amin',
            amount: 'SAR 35',
            payment: 'Wallet',
            reserveDate: '11:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#13',
            salonName: 'Braids & Layers',
            customerName: 'Fayez Ali',
            amount: 'SAR 25',
            payment: 'Cash',
            reserveDate: '11:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#14',
            salonName: 'Haute Hair',
            customerName: 'Sidra Amin',
            amount: 'SAR 45',
            payment: 'Online',
            reserveDate: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={{
                        hideOnSinglePage: true,
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewReservation}