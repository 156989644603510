import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewCouponManage = ({ setEditItem, setVisible, setSwitchText }) => {
    const [form] = Form.useForm();
    
  
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setSwitchText(true)
    };

    const columns = [
        {
            title: 'Coupon ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:120
        },
        {
            title: 'Coupon Code',
            dataIndex: 'couponCode',
            sorter: (a,b) => a.couponCode.localeCompare(b.couponCode),
            width:150,
        },
        {
            title: 'Number of Use',
            dataIndex: 'numberUse',
            sorter: (a,b) => a.numberUse.localeCompare(b.numberUse),
            width:150,
        },
        {
            title: 'Coupon Type',
            dataIndex: 'couponType',
            sorter: (a,b) => a.couponType.localeCompare(b.couponType),
            width: 150
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: (a,b) => a.amount.localeCompare(b.amount),
        },
        {
            title: 'Added By',
            dataIndex: 'addedBy',
            sorter: (a,b) => a.addedBy.localeCompare(b.addedBy),
            width:120,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            sorter: (a,b) => a.role.localeCompare(b.role),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
            width: 160,
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 120,
            render: (_, row) => (
                <Space>
                    <Switch size='small' defaultChecked onChange={onChange} />
                    <ActionButton
                        title='Edit item'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem(row?.couponCode)}}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            couponCode: 'FREESHIP75',
            numberUse: '67',
            couponType: 'Percentage',
            amount: '37',
            addedBy: 'Super Admin',
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#12',
            couponCode: 'BOGO50',
            numberUse: '87',
            couponType: 'Percentage',
            amount: '57',
            addedBy: 'Super Admin',
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#13',
            couponCode: 'HURRY',
            numberUse: '65',
            couponType: 'Fixed',
            amount: '44',
            addedBy: 'Super Admin',
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#14',
            couponCode: 'SALE25NOW',
            numberUse: '67',
            couponType: 'Percentage',
            amount: '37',
            addedBy: 'Super Admin',
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1300}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        showSorterTooltip={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {TableViewCouponManage}