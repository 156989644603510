import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Radio, Flex, Checkbox, Collapse } from "antd"
import { MyDatepicker, MySelect } from "../../Forms"
import { RightOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const ExportReservationDrawer = ({visible, onClose}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };  
    useEffect(() => {
        if (visible) {
            
        } else {
            form.resetFields();
        }
    }, [visible])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const getItems = () => [
        {
          key: '1',
          label: 'Select All',
          children: 
            <Space direction="vertical" className="px-3">
                <Checkbox>Reservation Number</Checkbox>
                <Checkbox>Salon Name</Checkbox>
                <Checkbox>Customer Name</Checkbox>
                <Checkbox>Contact Number</Checkbox>
                <Checkbox>Amount</Checkbox>
                <Checkbox>Payment Method</Checkbox>
                <Checkbox>Status</Checkbox>
                <Checkbox>Time Stamp</Checkbox>
            </Space>,
        },
    ]


  return (
    <Drawer
        title={'Export Reservations'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    Export
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">EXPORT DETAILS</Title>
                </Col>
                <Col span={24}>
                    <Flex gap={30}>
                        <Text className='fs-13'>Export As</Text>
                        <Radio.Group onChange={onChange} value={value}>
                            <Space className="mb-3">
                                <Radio value={1} className="fs-13">Excel</Radio>
                                <Radio value={2} className="fs-13">Pdf</Radio>
                            </Space>
                        </Radio.Group>
                    </Flex>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <MySelect
                        label= 'Salon Name'
                        name="name" 
                        size={'large'}
                        placeholder=''
                        required
                        mode="multiple"
                        message='Please choose salon name'
                        value={form.getFieldValue("name") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Salon Name'
                            },
                            {
                                key: 2,
                                name: 'City'
                            },
                            {
                                key: 3,
                                name: 'Gender'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <MySelect
                        label= 'Status'
                        name="status" 
                        size={'large'}
                        placeholder=''
                        required
                        mode="multiple"
                        message='Please choose status'
                        value={form.getFieldValue("status") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Cancelled'
                            },
                            {
                                key: 2,
                                name: 'Completed'
                            },
                            {
                                key: 2,
                                name: 'In progress'
                            },
                            {
                                key: 2,
                                name: 'New'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <MyDatepicker
                        rangePicker
                        label="Start & End Date"
                        name='date'
                        size='large'
                        required
                        message="Please select Start & End Date"
                        value={form.getFieldValue('date') || ''}
                        className='border-gray'
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">RESERVATION FIELD</Title>
                </Col>
                <Col span={24}>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        className="collapse-cs"
                        expandIcon={({ isActive }) => 
                            <Space>
                                <RightOutlined rotate={isActive ? 90 : 0} />
                                <Checkbox checked={isActive ? true : false} title={getItems?.label} />
                            </Space>
                        }
                        items={getItems()}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {ExportReservationDrawer}