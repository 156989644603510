import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const ServicesReservationTable = () => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:80
        },
        {
            title: 'Salon Name',
            dataIndex: 'salonName',
            sorter: (a,b) => a.salonName.localeCompare(b.salonName),
        },
        {
            title: 'Service Name',
            dataIndex: 'serviceName',
            sorter: (a,b) => a.serviceName.localeCompare(b.serviceName),
        },
        {
            title: 'Assigned To',
            dataIndex: 'assigned',
            sorter: (a,b) => a.assigned.localeCompare(b.assigned),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: (a,b) => a.amount.localeCompare(b.amount),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: (a,b) => a.date.localeCompare(b.date),
        },
        {
            title: 'To',
            dataIndex: 'to',
            sorter: (a,b) => a.to.localeCompare(b.to),
        },
        {
            title: 'From',
            dataIndex: 'from',
            sorter: (a,b) => a.from.localeCompare(b.from),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            salonName: 'Timeless Style',
            serviceName: 'Hair Dye',
            assigned: 'Sajid Hussain',
            amount: 'SAR 25',
            date: '9/18/16',
            to: '11:02',
            from: '01:03',
        },
        {
            key: '2',
            index:'#12',
            salonName: 'Hair Artistry',
            serviceName: 'Sameh Amin',
            assigned: 'Sajid Hussain',
            amount: 'SAR 25',
            date: '9/18/16',
            to: '11:02',
            from: '01:03',
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    showSorterTooltip={false}
                    pagination={false}
                />
            </Col>
        </Row>
    </div>
  )
}

export {ServicesReservationTable}