import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row, Space } from 'antd'
import { AlertModal, ModuleTopHeading, MyDatepicker, MyInput, ViewReviewModal, TableViewNotification, GridViewNotification, AddViewNotificationDrawer } from '../../components';


const NotificationPage = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ viewonly, setViewOnly ] = useState(null)


    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap>
                            <ModuleTopHeading name='Notifications' onClick={()=> setVisible(true)} />
                            <Flex gap={'small'}>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyInput
                                        name='name'
                                        label='Title'
                                        placeholder='e.g Hair Dye'
                                        value={form.getFieldValue("name") || ''}
                                        className='w-100 bg-transparent'
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-gray'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewNotification {...{ viewonly, setViewOnly, deleteitem, setDeleteItem, visible, setVisible }}/>
                            :
                            <GridViewNotification {...{ viewonly, setViewOnly, deleteitem, setDeleteItem, visible, setVisible }} />
                        }
                    </Col>
                </Row>
            </Card>
            <AddViewNotificationDrawer 
                visible={visible}
                viewonly={viewonly}
                onClose={()=> {setVisible(false); setViewOnly(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                onClose={()=>{setDeleteItem(false)}}
            />
        </div>
    )
}

export { NotificationPage }
