import React, { useState } from 'react';
import { Button, Card, Col, Flex, Form, Image, Row, Space } from 'antd';
import { ModuleTopHeading, MyInput } from '../../components';

const ProfileSettingPage = () => {
    const [form] = Form.useForm();
    

    return (
        <div>
            <Card className="shadow-c radius-12 border0">
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <div>
                                <ModuleTopHeading name="Profile Settings" />
                            </div>
                        </Col>
                        <Col span={24}>
                            <Flex justify='center' className='my-4'>
                                <Image src='/assets/images/bannerimg-1.png' width={150} height={90} style={{objectFit:'cover'}} className='radius-12'/>
                            </Flex>
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="name"
                                label="Name"
                                placeholder="Super Admin"
                                size='large'
                                value={form.getFieldValue("name") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="email"
                                label="Email Address"
                                placeholder="shafrah@gmail.com"
                                size='large'
                                value={form.getFieldValue("email") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col span={24}>
                            <Space className="w-100 right">
                                <Button 
                                    className="btncancel"
                                >
                                    Cancel
                                </Button>
                                <Button  
                                    block
                                    className='btnsave'
                                    type="primary"
                                    htmlType="submit"
                                    onClick={()=> form.submit()}
                                >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
};

export { ProfileSettingPage };
