import React, { useState } from 'react'
import { Modal, Space, Typography, Button, Image, Col, Row, Form, Flex } from 'antd'
import { apiCalls } from '../../../shared/Apis';
import { MySelect } from '../../Forms';
const { Title, Text } = Typography
const CheckStatusModal = ({visible,onClose}) => {
    
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)

  return (
    <div>
        <Modal width={500} className='shadow-c'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Flex justify='center'>
                            <Image src='/assets/icons/statuscheck.png' width={50} preview={false} />
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex justify='center'>
                            <Title level={5} className='my-0'>Ticket Status</Title>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label= 'Status'
                            name="status" 
                            size={'large'}
                            required
                            message='Please choose status'
                            value={form.getFieldValue("status") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Todo'
                                },
                                {
                                    key: 2,
                                    name: 'In Progress'
                                },
                                {
                                    key: 3,
                                    name: 'Closed'
                                },
                                {
                                    key: 4,
                                    name: 'Re-Open'
                                },
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <Space style={{justifyContent:"center",marginTop:'15px'}} className="w-100">
                            <Button
                                onClick={onClose}
                                className='btncancel'
                            >
                                Cancel
                            </Button>
                            <Button  
                                block
                                type="primary"
                                className='btnsave brand-bg text-white'
                                loading={loading}
                                onClick={()=>{
                                    // DeleteProductApi();
                                }}
                                
                            >
                                Confirm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {CheckStatusModal}