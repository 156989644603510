import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space } from 'antd'
import { AlertModal, ModuleTopHeading, MyDatepicker, MyInput, MyselectFix, ViewReviewModal, TableViewReservation, GridViewReservation, ExportReservationDrawer, DownloadPdfModal, ViewReservationDrawer, MySelect } from '../../components';


const ReservationPage = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ viewonly, setViewOnly ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ docitem, setDocItem ] = useState(false)
    const [ docname, setDocName ] = useState(null)
    const [ viewdrawer, setViewDrawer ] = useState(false)
    const [ changeinput, setChangeInput ] = useState('Salon Name')

    const handleChangeFilter = (e) => {
        setChangeInput(e === 'Salon Name' ? true : false)
    }

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap>
                            <ModuleTopHeading name='Reservations' />
                            <Flex gap={'small'}>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        onChange={handleChangeFilter}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Salon Name',
                                            },
                                            {
                                                key: 2,
                                                name: 'Customer Name',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    {
                                        changeinput ? (
                                            <MyInput
                                                name='salonName'
                                                label='Salon Name'
                                                value={form.getFieldValue("salonName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : (
                                            <MyInput
                                                name='customerName'
                                                label='Customer Name'
                                                value={form.getFieldValue("customerName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        )
                                    }
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-gray'
                                    />
                                </Col>
                                <Col lg={8} md={12} sm={24} xs={12}>
                                    <Space className='w-100'>
                                        <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                        <Button className='btnsave pad-filter bg-secondary-color text-brand border0 margin-5'
                                            onClick={()=> setVisible(true)}
                                        >
                                            <Image src='/assets/icons/file.png' preview={false} width={22} />
                                            Export
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewReservation {...{ viewdrawer, setViewDrawer, docitem, setDocItem, docname, setDocName, viewonly, setViewOnly, deleteitem, setDeleteItem }}/>
                            :
                            <GridViewReservation {...{ viewdrawer, setViewDrawer, viewonly, setViewOnly, deleteitem, setDeleteItem, setDocName, setDocItem  }} />
                        }
                    </Col>
                    
                </Row>
            </Card>
            <ViewReservationDrawer 
                visible={viewdrawer}
                viewonly={viewonly}
                onClose={()=> {setViewDrawer(false);setViewOnly(null)}}
            />
            <ExportReservationDrawer 
                visible={visible}
                onClose={()=> setVisible(false)}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                onClose={()=>{setDeleteItem(false)}}
            />
            <DownloadPdfModal 
                visible={docitem}
                docname={docname}
                onClose={()=>{setDocItem(false);setDocName(null)}}
            />
        </div>
    )
}

export { ReservationPage }
