import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col, Typography, Image, Flex, Button} from "antd"
import { MyInput } from "../../Forms"
import { ArrowDownOutlined, DownOutlined } from "@ant-design/icons";
import { ActionButton } from "../../PageComponents";

const { Title, Text } = Typography;

const ViewSupportDrawer = ({visible, onClose, viewonly}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && viewonly) {
            
        } else {
            form.resetFields();
        }
    }, [visible, viewonly])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={
            <>
                Ticket: {viewonly?.ticketnumber}
            </>
        }
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={900}
        footer={false}
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0 fw-500">TICKET DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Salon Name'
                        name='salonName'
                        placeholder='e.g Kidzo Doe'
                        size={'large'}
                        value={form.getFieldValue("salonName") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        name="contactNo"
                        label="Contact Number"
                        type='number'
                        size={'large'}
                        value={form.getFieldValue("contactNo") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Category'
                        name='category'
                        placeholder='e.g System'
                        size={'large'}
                        value={form.getFieldValue("category") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Priority'
                        name='priority'
                        placeholder='e.g High'
                        size={'large'}
                        value={form.getFieldValue("priority") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Status'
                        name='status'
                        placeholder='e.g Pending'
                        size={'large'}
                        value={form.getFieldValue("status") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Subject'
                        name='subject'
                        placeholder='e.g Subject'
                        size={'large'}
                        value={form.getFieldValue("subject") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='viewDownload space-between-align mb-3'>
                        <Text className='text-white fs-12'>
                            img.jpg
                        </Text>
                        <ActionButton
                            title='Delete'
                            icon={<Image src="/assets/icons/download.png" preview={false} width={25} />}
                            // onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                            type='ghost'
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <MyInput
                        textArea
                        label='Ticket Description'
                        name='ticketDescription'
                        placeholder='Description'
                        size={'large'}
                        value={form.getFieldValue("ticketDescription") || ''}
                        disabled
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0 fw-500">COMMENTS</Title>
                </Col>
                <Col span={24}>
                    <Flex gap={5} align="center">
                        <Image src="/assets/images/av-1.png" preview={false} width={45} height={45} style={{borderRadius: 50}} />
                        <Text>
                            <strong>{viewonly?.name} </strong>
                            added a comment 2 hours ago
                        </Text>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Flex justify="space-between" className="py-2 px-2 bg-light-gray" align="center">
                        <Text className="fs-13 fw-400">
                            The problem is not solved till yet.
                        </Text>
                        <Button className='border0 bg-transparent text-gray fs-12'>
                            <img src="/assets/icons/reply.png" width={15} alt="" />
                            replay
                        </Button>
                    </Flex>
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {ViewSupportDrawer}