import { useState } from "react"
import { Space, Typography, Avatar} from "antd"
import {CaretDownOutlined, UserOutlined} from '@ant-design/icons'
import UserProfileDrawer from "./UserProfileDrawer"
const {Title}= Typography

export const UserProfile = () => {
    const [visible, setVisible]= useState(false)
    return (
        <>
          <Space 
            style={{ cursor: 'pointer' }}
            onClick={()=> setVisible(true)}
          >
            <Avatar
              size={36}
              icon={<img src="/assets/images/av-1.png" />}
            />
          </Space>
          <UserProfileDrawer
            visible={visible}
            onClose={()=> setVisible(false)}
          />
        </>
    )
}