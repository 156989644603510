import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewCustomer = ({setDeleteItem, setSwitchText}) => {
    const { token } = useToken();

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };
    

    const data = [
        {
            title:'Nadeem Abbas',
            gender:'Male',
            key:'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Salim Al Tajir',
            gender:'Male',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Mohammed Darwish',
            gender:'Male',
            key:'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Jihad Bakir',
            gender:'Male',
            key:'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Zuhair Hafeez',
            gender:'Male',
            key:'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Sameh Amin',
            gender:'Female',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Fahd Bakir',
            gender:'Male',
            key:'active',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
        {
            title:'Ziyad Abdullah',
            gender:'Male',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
            ]
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            className='card-border'
                            actions={[
                                <Space gap={10}>
                                    <Switch size='small' defaultChecked onChange={onChange} />
                                    <ActionButton
                                        title='Delete item'
                                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                                        onClick={() => { setDeleteItem({name: cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={5} align='center'>
                            <Flex align='center' gap={5} className='pt-3'>
                                {
                                    cdata.key === 'active' ?
                                    <img src="/assets/icons/online.png" width={10} alt="" />
                                    :
                                    <img src="/assets/icons/offline.png" width={10} alt="" />
                                }
                                <Text strong>{cdata?.title}</Text>
                            </Flex>
                            <Text className='text-gray'>{cdata?.gender}</Text>
                            <Flex vertical align='start' gap={10}  className='my-3 w-100'>
                                {
                                    cdata?.contacts?.map((contact,index)=>
                                        <Flex gap={5} align='center' key={index}>
                                            <div className='icon bg-secondary-color'>
                                                <img src={"/assets/icons/"+contact?.icons} width={20} alt="" />
                                            </div>
                                            <Text strong>{contact?.title}</Text>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
        </Row>
    </div>
  )
}

export {GridViewCustomer}