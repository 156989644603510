import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewCustomer = ({ setDeleteItem, setSwitchText }) => {
    const [form] = Form.useForm();
    
  
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:80
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            sorter: (a,b) => a.customerName.localeCompare(b.customerName),
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            sorter: (a,b) => a.contactNumber.localeCompare(b.contactNumber),
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            sorter: (a,b) => a.gender.localeCompare(b.gender),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space align='center'>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <Switch size='small' defaultChecked onChange={onChange} />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.gender}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            customerName: 'Sajid Hussain',
            contactNumber: '+966 110 113 087',
            gender: 'Male',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#12',
            customerName: 'Sameh Amin',
            contactNumber: '+966 798 328 129',
            gender: 'Female',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#13',
            customerName: 'Fayez Ali',
            contactNumber: '+966 798 328 129',
            gender: 'Male',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#14',
            customerName: 'Sidra Amin',
            contactNumber: '+966 798 328 129',
            gender: 'Female',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        showSorterTooltip={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {TableViewCustomer}