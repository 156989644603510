import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col, Typography, Flex, Card, Divider, Button} from "antd";

const { Title, Text } = Typography;

const ViewReservationDrawer = ({visible, onClose, viewonly}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && viewonly) {
            
        } else {
            form.resetFields();
        }
    }, [visible, viewonly])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const data = [
        {
            title:'CUSTOMER DETAILS',
            key:'customerdetail',
            innercontent:[
                {
                    name:'Mohamaad Ali',
                    phoneno:'+966 654 765 456',
                    amount:'Credit Card - Paid',
                    status:'Completed'
                }
            ]
        },
        {
            title:'SERVICES DETAILS',
            key: 'service',
            innercontent:[
                {
                    name:'Hair Wash',
                    phoneno:'11:00am - 11:30am (30 min)',
                    amount:'SAR 700',
                    status:'By M.Daud'
                },
                {
                    name:'Long Hair Cut',
                    phoneno:'11:30am - 12:30pm (60 min)',
                    amount:'SAR 1200',
                    status:'By M.Daud'
                },
                {
                    name:'Hair Dye',
                    phoneno:'01:00pm - 02:00pm (60 min)',
                    amount:'SAR900',
                    status:'By M.Taha'
                }
            ],
        },
        {
            title:'PRODUCT DETAILS',
            key:'productdetail',
            innercontent:[
                {
                    name:'Keratin Cream ×4',
                    phoneno:'Loreal',
                    amount:'SAR 1600',
                }
            ]
        },
        {   
            lastdata:[
                {
                    title: 'Sub Total',
                    value: 'SAR 35,000',
                },
                {
                    title: 'Tip',
                    value: 'SAR 50',
                },
                {
                    title: 'Discount',
                    value: '- SAR 50',
                },
                {
                    title: 'Commission',
                    value: 'SAR 5',
                },
                {
                    title: 'Taxes',
                    value: 'SAR 100',
                },
            ]
        }
    ]


  return (
    <Drawer
        title={
            <Flex justify="space-between">
                <>{viewonly?.name}</>
                <Text className='fw-400 text-gray'>{viewonly?.date}</Text>
            </Flex>
        }
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={false}
    >
        
        <Row gutter={[16,16]}>
            {
                data?.map((data,index)=> 
                    <Col span={24} key={index}>
                        <Flex vertical>
                            <Title level={5} className="mb-3 mt-0 fw-500">{data?.title}</Title>
                            {
                                data?.innercontent?.map((content,index)=>
                                    <Card className={
                                            data?.key === 'customerdetail' ?
                                            "border-brand card-cs bg-brand-light radius-12 mb-3":
                                            "card-cs radius-12 mb-3 card-border"
                                        } 
                                        key={index}
                                    >
                                        <Flex vertical gap={3}>
                                            <Flex justify="space-between">
                                                <Text className="fs-14 fw-500">{content?.name}</Text>
                                                {
                                                    data?.key === 'service' &&
                                                    <Button className="bg-transparent border0 p-0 text-brand fs-13 fw-400">
                                                        Edit
                                                    </Button>
                                                }
                                            </Flex>
                                            <Text className="fs-14 text-gray">{content?.phoneno}</Text>
                                            <Text className="fs-14 text-gray">{content?.amount}</Text>
                                            {
                                                data?.key !== 'productdetail' ?
                                                <Text className={
                                                    data?.key === 'customerdetail' ? 
                                                    "fs-14 fw-500 text-dark-green": "fs-14 fw-500 text-gray"
                                                }
                                                >
                                                    {content?.status}
                                                </Text>
                                                :
                                                <></>
                                            }
                                        </Flex>
                                    </Card>
                                )
                            }
                        </Flex>
                    </Col>
                )
            }
            <Col span={24}>
                <Divider className="my-2" />
                {
                    data[3]?.lastdata?.map((total,index)=>
                        <div key={index}>
                            <Flex justify="space-between" className="mb-2">
                                <Text className="fs-14">
                                    {total?.title}
                                </Text>
                                <Text className={
                                    total?.value < 0 + '-' ? "fs-13 fw-500 text-inactive" : "fs-13 fw-500 text-active"
                                }>
                                    {total?.value}
                                </Text>
                            </Flex>
                            <Divider className="m-0" /> 
                        </div>
                    )
                }
                <hr />
                <Flex justify="space-between">
                    <Text strong>
                        Final Amount
                    </Text>
                    <Text strong>
                        SAR 35,105
                    </Text>
                </Flex>
            </Col>
        </Row>
    </Drawer>
  )
}

export {ViewReservationDrawer}