import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch, Divider } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewNotification = ({setEditItem, setDeleteItem, setVisible }) => {
    const { token } = useToken();

    

    const data = [
        {
            title:'Customer Insight: Rapid NPS Every 6 Hours',
            customer:'All Customers',
        },
        {
            title:'Feedback Time: NPS Every 10 Hours',
            customer:'All Customers',
        },
        {
            title:'Rate Your Exclientience: Daily 24-Hour Feedback',
            customer:'Specific Customers',
        },
        {
            title:'Instant Feedback: Rate Us Every 24 Hours',
            customer:'Specific Customers',
        },
        {
            title:'One-Click Rating: Evaluate Every 24 Hours',
            customer:'All Customers',
        },
        {
            title:'Feedback Time: Rate Every 8 Hours',
            customer:'All Customers',
        },
        {
            title:'Customer Feedback: Rate Us Every 8 Hours',
            customer:'Specific Customers',
        },
        {
            title:'Evaluate Us: Quick Feedback Every 10 Hours',
            customer:'Specific Customers',
        },


    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            className='height-100 card-h card-border'
                            actions={[
                                <Space gap={10}>
                                    <ActionButton
                                        title='View'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                                        onClick={() => {setVisible(true); setEditItem({name: cdata?.title})}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Delete item'
                                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                                        onClick={() => { setDeleteItem({name: cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={5} align='center' className='pt-3'>
                            <Text strong className='text-align'>{cdata?.title}</Text>
                            <Text className='text-gray fs-13'>{cdata?.customer}</Text>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
        </Row>
    </div>
  )
}

export {GridViewNotification}