import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space, Table, Typography } from 'antd'
import { ActionButton, AssignTicketModal, CheckStatusModal, CommentsDrawer, ModuleTopHeading, MyDatepicker, MyInput, MySelect, MyselectFix, ViewReviewModal, ViewSupportDrawer } from '../../components';

const { Text } = Typography
const SupportHelpdesk = () => {
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ viewonly, setViewOnly ] = useState(null)
    const [ assign, setAssign ] = useState(false)
    const [ assignid, setAssignId ] = useState(null)
    const [ checkstatus, setCheckStatus ] = useState(false)
    const [ changeinput, setChangeInput ] = useState('Ticket Number')

    const handleChangeFilter = (e) => {
        setChangeInput(e)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:80
        },
        {
            title: 'Ticket Number',
            dataIndex: 'ticketNumber',
            sorter: (a,b) => a.ticketNumber.localeCompare(b.ticketNumber),
            width: 200,
        },
        {
            title: 'Salon Name',
            dataIndex: 'salonName',
            sorter: (a,b) => a.salonName.localeCompare(b.salonName),
            width: 200,
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            sorter: (a,b) => a.contactNumber.localeCompare(b.contactNumber),
            width: 200,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            sorter: (a,b) => a.category.localeCompare(b.category),
            width:120,
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            sorter: (a,b) => a.subject.localeCompare(b.subject),
            width:130,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status === 1 ? (
                        <Space align='center' className='cursor'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Resolved</Text>
                        </Space>
                    ) : status !== 2 ? (
                        <Space align='center' className='cursor' onClick={()=> setCheckStatus(true)}>
                            <img src="/assets/icons/inprogress.png" width={'8px'} alt="" />
                            <Text className='text-progress' style={{transform:"translateY(-2px)",display:'block'}}>In Progress</Text>
                        </Space>
                    ) :  status === 3 ?(
                        <Space align='center' className='cursor' onClick={()=> setCheckStatus(true)}>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Pending</Text>
                        </Space>
                    ) : (
                        <Space align='center' className='cursor' onClick={()=> setCheckStatus(true)}>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Closed</Text>
                        </Space>
                    )
                );
            },
            width: 170,
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignedTo',
            sorter: (a,b) => a.assignedTo.localeCompare(b.assignedTo),
            render: (assignedTo) => <a onClick={()=>setAssign(true)}>{assignedTo}</a>,
            width: 170,
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            sorter: (a,b) => a.priority.localeCompare(b.priority),
            render: (priority) => {
                return (
                    priority !== 1 ? (
                        <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>High</Text>
                    ) : priority === 2 ? (
                        <Text className='pink-text' style={{transform:"translateY(-2px)",display:'block'}}>Medium</Text>
                    ) : priority === 3 ? (
                        <Text className='text-brand' style={{transform:"translateY(-2px)",display:'block'}}>Low</Text>
                    ) : (
                        <Text style={{transform:"translateY(-2px)",display:'block'}}>Urgent</Text>
                    )
                );
            },
            width: 170,
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
            width: 170,
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 130,
            align:'center',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setViewOnly({ticketnumber: row?.ticketNumber,name: row?.salonName})}}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            ticketNumber: '487441',
            salonName: 'Classique Curls',
            contactNumber: '+966 110 113 087',
            category: 'Technical',
            subject: 'Order_017926',
            assignedTo: 'Not Assigned',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#12',
            ticketNumber: '547441',
            salonName: 'HH Salon',
            contactNumber: '+966 110 113 087',
            category: 'Technical',
            subject: 'Order_017926',
            assignedTo: 'Not Assigned',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#13',
            ticketNumber: '109441',
            salonName: 'Classique Curls',
            contactNumber: '+966 110 113 087',
            category: 'Technical',
            subject: 'Order_017926',
            assignedTo: 'Not Assigned',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#14',
            ticketNumber: '187917',
            salonName: 'Classique Curls',
            contactNumber: '+966 110 113 087',
            category: 'Technical',
            subject: 'Order_017926',
            assignedTo: 'Not Assigned',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading name='Support & Helpdesk' />                      
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        onChange={handleChangeFilter}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Ticket Number',
                                            },
                                            {
                                                key: 2,
                                                name: 'Salon Name',
                                            },
                                            {
                                                key: 3,
                                                name: 'Assignee Name',
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    {
                                        changeinput === 'Ticket Number' ? (
                                            <MyInput
                                                type='number'
                                                name='ticketName'
                                                label='Ticket Number'
                                                value={form.getFieldValue("ticketName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : changeinput === 'Salon Name' ? (
                                            <MyInput
                                                name='salonName'
                                                label='Salon Name'
                                                value={form.getFieldValue("salonName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : (
                                            <MyInput
                                                name='assigneeName'
                                                label='Assignee Name'
                                                value={form.getFieldValue("assigneeName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        )
                                    }
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-gray'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1500}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            showSorterTooltip={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <ViewSupportDrawer 
                visible={visible}
                viewonly={viewonly}
                onClose={()=> {setVisible(false);setViewOnly(null)}}
            />
            <CheckStatusModal 
                visible={checkstatus}
                onClose={()=> setCheckStatus(false)}
            />
            <AssignTicketModal 
                visible={assign}
                onClose={()=>setAssign(false)}
            />
        </div>
    )
}

export { SupportHelpdesk }
