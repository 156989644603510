import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Checkbox, theme, Collapse } from "antd"
import { MyInput } from "../../Forms"
import { RightOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const AddroleDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const { token } = theme.useToken();

    const getItems = (panelStyle) => [
        {
          key: '1',
          label: 'Dashboard',
          children: 
            <Space direction="vertical" className="px-3">
                <Checkbox>Graph & Analytics</Checkbox>
                <Checkbox>View beauty salons graph</Checkbox>
                <Checkbox>View customers graph</Checkbox>
                <Checkbox>View beauty salons reservation graph</Checkbox>
                <Checkbox>View active & inactive salons graph</Checkbox>
                <Checkbox>View male & female beauty salons piechart</Checkbox>
                <Checkbox>View male & female customers piechart</Checkbox>
            </Space>,
          style: panelStyle,
        },
        {
          key: '2',
          label: 'Beauty Salons',
          children: 
            <Space direction="vertical" className="px-3">
                <Checkbox>Add beauty salons</Checkbox>
                <Checkbox>Edit beauty salons</Checkbox>
                <Checkbox>Active/inactive beauty salons</Checkbox>
                <Checkbox>Delete beauty salons</Checkbox>
                <Checkbox>View salon gallery</Checkbox>
            </Space>,
          style: panelStyle,
        },
        {
          key: '3',
          label: 'Customers',
          children: 
            <Space direction="vertical" className="px-3">
                <Checkbox>Add Customers</Checkbox>
                <Checkbox>Edit Customers</Checkbox>
                <Checkbox>Active/inactive Customers</Checkbox>
                <Checkbox>Delete Customers</Checkbox>
            </Space>,
          style: panelStyle,
        },
        {
            key: '4',
            label: 'Employees',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>Add employees</Checkbox>
                  <Checkbox>Edit employees</Checkbox>
                  <Checkbox>Active/inactive employees</Checkbox>
                  <Checkbox>Delete employees</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '5',
            label: 'Role',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>Add role</Checkbox>
                  <Checkbox>Edit role</Checkbox>
                  <Checkbox>Delete role</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '6',
            label: 'Reservation',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>View reservation</Checkbox>
                  <Checkbox>Delete reservation</Checkbox>
                  <Checkbox>Export reservation</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '7',
            label: 'Coupon Management',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>Add coupons</Checkbox>
                  <Checkbox>Edit coupons</Checkbox>
                  <Checkbox>Active/inactive coupons</Checkbox>
                  <Checkbox>Delete coupons</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '8',
            label: 'Notifications',
            children: 
              <Space direction="vertical" className="px-3">
                  <Checkbox>View notifications</Checkbox>
                  <Checkbox>Add notifications</Checkbox>
                  <Checkbox>Delete notifications</Checkbox>
              </Space>,
            style: panelStyle,
        },
        {
            key: '9',
            label: 'Support & Helpdesk',
            children: 
                <Space direction="vertical" className="px-3">
                    <Checkbox>View tickets</Checkbox>
                    <Checkbox>Assign tickets</Checkbox>
                    <Checkbox>Change status of ticket</Checkbox>
                    <Checkbox>Delete ticket</Checkbox>
                </Space>,
            style: panelStyle,
        },

        {
            key: '10',
            label: 'Terms & Conditions',
            children: <Checkbox className="px-3">Edit terms & conditions</Checkbox>,
            style: panelStyle,
        },
        {
            key: '11',
            label: 'Privacy Policy',
            children: <Checkbox className="px-3">Edit privacy policy</Checkbox>,
            style: panelStyle,
        },
        {
            key: '12',
            label: 'Settings',
            children: 
                <Space direction="vertical"  className="px-3">
                    <Checkbox>View notifications</Checkbox>
                    <Checkbox>Add notifications</Checkbox>
                    <Checkbox>Delete notifications</Checkbox>
                </Space>,
            style: panelStyle,
        },
    ];

  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    
    const panelStyle = {
        marginBottom: 0,
        padding: 0,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none',
    };


  return (
    <Drawer
        title={edititem? 'Edit role' : 'Add role'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {
                        edititem ? 'Update' : 'Save'
                    }
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">ROLE DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Role Name'
                        name='roleName'
                        placeholder='e.g Data Analyst'
                        required
                        size={'large'}
                        message='Please enter role name'
                        value={form.getFieldValue("roleName") || ''}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0 fw-500">PERMISSIONS</Title>
                </Col>
                <Col span={24}>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        className="collapse-cs"
                        expandIcon={({ isActive }) => 
                            <Space>
                                <RightOutlined rotate={isActive ? 90 : 0} />
                                <Checkbox checked={isActive ? true : false} title={getItems?.label} />
                            </Space>
                        }
                        style={{
                            background: token.colorBgContainer,
                        }}
                        items={getItems(panelStyle)}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddroleDrawer}