import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput } from '../../Forms';

const { Title } = Typography

const CustomerLinechart = () => {
  const [order, setOrder] = useState(0);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#">Today</a>, key: 0 },
    { label: <a href="#">Last Week</a>, key: 1 },
    { label: <a href="#">Last Month</a>, key: 2 },
    { label: <a href="#">Quarter</a>, key: 3 },
    { label: <a href="#">Year</a>, key: 4 },
  ];

  const onClick = ({ key }) => {
    // key = parseInt(key) + 1;
    setOrder(key);
    // filter(key);
  };

  const chartData = {
    series: [
      {
        name: 'Data Series',
        data: [0, 5, 10, 14, 16, 17, 20, 22, 25],
      },
    ],
    options: {
      chart: {
        type: 'line',
        toolbar:{
          show:false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      colors: ['#338EE3'],
      legend: {
        show: false,
      },
    },
  };

  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <ModuleTopHeading name='Customer' />
          <Form form={form} layout="vertical" >
            <Flex justify='end' gap={10}>
              <Dropdown
                menu={{ items, onClick }}
                trigger={['click']}
                className='margin-top'
              >
                <Button className='btnsave radius-8 pad-filter bg-secondary-color text-brand border0'>
                  <Space>
                    {
                      order == 0 ? 'Today' : 
                      order == 1 ? 'Last Week' :
                      order == 2 ? 'Last Month' :
                      order == 3 ? 'Quarter' :
                      order == 4 ? 'Year' : ''
                    }  
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Flex>
          </Form>
        </Flex>
        <Form
            form={form}
            layout="vertical"
            className='pt-3'
        >
            <Space size={10} wrap>
                <MyInput
                    name='name'
                    label='City'
                    placeholder='e.g Riyadh'
                    value={form.getFieldValue("name") || ''} 
                    className='w-100 bg-transparent'
                    onChange={(e)=>e.target.value}
                />
                <Button className='brand-bg margin-5 btnsave text-white'>Search</Button>
            </Space>
            <Title level={5} className='m-0 fs-14 fw-500'>Today : 23</Title>
        </Form>
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={200}
          />
        </div>
      </Card>
    </div>
  );
};

export { CustomerLinechart };
