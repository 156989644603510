import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	addandbox,
	mastervendor
} from "./action"
const store = configureStore({
	reducer: {
		login,
		addandbox,
		mastervendor
	},
})
export default store