import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewReviews = ({ setDeleteItem, setVisible }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:80
        },
        {
            title: 'Reservation No',
            dataIndex: 'regNo',
            sorter: (a,b) => a.regNo.localeCompare(b.regNo),
        },
        {
            title: 'Salon Name',
            dataIndex: 'salonName',
            sorter: (a,b) => a.salonName.localeCompare(b.salonName),
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            sorter: (a,b) => a.customerName.localeCompare(b.customerName),
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            width:170,
            sorter: (a,b) => a.contactNumber.localeCompare(b.contactNumber),
        },
        {
            title: 'Salon Rate',
            dataIndex: 'salonRate',
            sorter: (a,b) => a.salonRate - b.salonRate,
            render: (salonRate) => (
                <Space size={0}>
                    {Array(salonRate).fill(0).map((_, idx) => (
                        <Image key={idx} src='/assets/icons/star.png' width={20} preview={false} />
                    ))}
                </Space>
            )
        },
        {
            title: 'Emp. Rate',
            dataIndex: 'empRate',
            sorter: (a,b) => a.empRate - b.empRate,
            render: (empRate) => (
                <Space size={0}>
                    {Array(empRate).fill(0).map((_, idx) => (
                        <Image key={idx} src='/assets/icons/star.png' width={20} preview={false} />
                    ))}
                </Space>
            )
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Review'
                        shape="circle"
                        icon={<Image src='/assets/icons/review.png' width={16} preview={false} />}
                        onClick={() => { setVisible({name: row?.clientName});}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.clientName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            regNo: '478',
            salonName:'Hair Flow',
            customerName: 'Sajid Hussain',
            contactNumber: '+966 642 323 134',
            salonRate: 3,
            empRate: 3,
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#12',
            regNo: '508',
            salonName:'Classique Curls',
            customerName: 'Sameh Amin',
            contactNumber: '+966 798 328 129',
            salonRate: 5,
            empRate: 5,
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#13',
            regNo: '600',
            customerName: 'Fayez Ali',
            salonName:'Uptown Hair',
            contactNumber: '+966 798 328 129',
            salonRate: 4,
            empRate: 5,
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#14',
            regNo: '620',
            customerName: 'Sidra Amin',
            salonName:'Hair Affair',
            contactNumber: '+966 798 328 129',
            salonRate: 4,
            empRate: 4,
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1300}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        showSorterTooltip={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {TableViewReviews}