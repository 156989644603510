import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Radio } from "antd"
import { MyInput, MySelect } from "../../Forms"

const { Title } = Typography;

const AddViewNotificationDrawer = ({visible, onClose, viewonly}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };  
  
    useEffect(() => {
        if (visible && viewonly) {
            
        } else {
            form.resetFields();
        }
    }, [visible, viewonly])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }


  return (
    <Drawer
        title={viewonly?'View notification':'Add notification'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            !viewonly ?
                <Space className="w-100 right">
                    <Button 
                        onClick={closeDrawer}
                        className="btncancel"
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        className='btnsave'
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={()=> form.submit()}
                    >
                        Save
                    </Button>
                </Space>
            :
            false
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">NOTIFICATION DETAILS</Title>
                </Col>
                <Col span={24}>
                    <MyInput
                        label='Notification Title'
                        name='title'
                        placeholder='e.g Lorem Ipsum'
                        required
                        size={'large'}
                        message='Please enter notification title'
                        value={form.getFieldValue("title") || ''}
                    />
                </Col>
                <Col span={24}>
                    <MyInput
                        textArea
                        label='Notification Body'
                        name='notifBody'
                        placeholder='e.g Lorem ipsum'
                        size={'large'}
                        required
                        message='Please enter notification body'
                        value={form.getFieldValue("notifBody") || ''}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">SENDING DETAILS</Title>
                </Col>
                <Radio.Group onChange={onChange} value={value}>
                    <Space direction="vertical" className="px-2 mb-3">
                        <Radio value={1}>All Salons</Radio>
                        <Radio value={2}>Specific Salons</Radio>
                    </Space>
                </Radio.Group>
                {
                    value === 2?
                    <>
                        <Col span={24}>
                            <MySelect
                                label= 'Select Location'
                                name="location" 
                                size={'large'}
                                required
                                mode="multiple"
                                message='Please choose location'
                                value={form.getFieldValue("location") || ''}
                                options={[
                                    {
                                        key: 1,
                                        name: 'Saudia'
                                    },
                                    {
                                        key: 2,
                                        name: 'France'
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={24}>
                            <MySelect
                                label= 'Select Gender'
                                name="gender" 
                                size={'large'}
                                required
                                message='Please choose gender'
                                value={form.getFieldValue("gender") || ''}
                                options={[
                                    {
                                        key: 1,
                                        name: 'Male'
                                    },
                                    {
                                        key: 2,
                                        name: 'Female'
                                    },
                                    {
                                        key: 3,
                                        name: 'Both'
                                    },
                                ]}
                            />
                        </Col>
                    </>
                    :
                    <></>
                }
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddViewNotificationDrawer}