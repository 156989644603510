import React, { useState } from 'react';
import { Card, Form, Flex, Space, Typography, Button, Dropdown } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput } from '../../Forms';
import { DownOutlined } from '@ant-design/icons';

const { Text } = Typography

const CustomerDonutchart = () => {
  const [order, setOrder] = useState(4);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#">Today</a>, key: 0 },
    { label: <a href="#">Last Week</a>, key: 1 },
    { label: <a href="#">Last Month</a>, key: 2 },
    { label: <a href="#">Last Quarter</a>, key: 3 },
    { label: <a href="#">Last Year</a>, key: 4 },
  ];

  const onClick = ({ key }) => {
    // key = parseInt(key) + 1;
    setOrder(key);
    // filter(key);
  };

  const chartData = {
    series: [55, 50],
    options: {
      chart: {
        type: 'donut',        
        height: 350,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + "%";
        },
        style: {
          fontSize: '10px',
          colors: ['#fff'],
        },
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      colors: ['#F56C89' , '#00C7BE'],
      legend: {
        show: false,
      },
    },
  };
  

  const data = [
    {
      title:'Male',
      iconcr:'skyish-cr.png',
      per:'43.29%',
      amount:'1500',
      key:'skyish',
    },
    {
      title:'Female',
      iconcr:'pink-cr.png',
      per:'36.16%',
      amount:'2500'
    },
  ]

  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Flex justify='space-between' align='center' wrap gap={10}>
            <ModuleTopHeading name='Male & Female Customer' />
            <Form form={form} layout="vertical">
                <Flex justify='end' gap={10}>
                    <Dropdown
                        menu={{ items, onClick }}
                        trigger={['click']}
                        className='margin-top'
                    >
                        <Button className='btnsave pad-filter radius-8 bg-secondary-color text-brand border0'>
                        <Space>
                            {
                              order == 0 ? 'Today' : 
                              order == 1 ? 'Last Week' :
                              order == 2 ? 'Last Month' :
                              order == 3 ? 'Last Quarter' :
                              order == 4 ? 'Last Year' : ''
                            }  
                            <DownOutlined />
                        </Space>
                        </Button>
                    </Dropdown>
                </Flex>
            </Form>
        </Flex>
        <Form
            form={form}
            layout="vertical"
        >
            <Space size={10} wrap>
              <MyInput
                name='name'
                label='City'
                placeholder='Search here ...'
                value={form.getFieldValue("name") || ''} 
                className='w-100 bg-transparent'
                onChange={(e)=>e.target.value}
              />
              <Button className='brand-bg btnsave margin-5 text-white'>Search</Button>
            </Space>
        </Form>
        <Space align='center' size={30} wrap>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="donut"
            width={'100%'}
          />
          <Flex vertical gap={10} wrap>
            {
              data?.map((statis,index)=>
                <Flex gap={40} key={index}>
                  <Space size={10}>
                    <Flex align='center' gap={5}>
                      <img src={'/assets/icons/'+statis?.iconcr} width={20} />
                      <Text className='text-gray'>{statis?.title}</Text>
                    </Flex>
                    <Text className={statis?.key === 'skyish' ? 'skyish-color' : 'pink-text'} strong>{statis?.per}</Text>
                    {/* <Flex align='center' gap={5}>
                      <img src={"/assets/icons/"+statis?.iconar} width={20} alt="" />
                      
                    </Flex> */}
                  </Space>
                  <Text className='text-gray'>{statis?.amount}</Text>
                </Flex>
              )
            }
          </Flex>
        </Space>
      </Card>
    </div>
  );
};

export { CustomerDonutchart };
