import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewNotification = ({ setDeleteItem, setVisible, setViewOnly }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:80
        },
        {
            title: 'Title',
            dataIndex: 'title',
            sorter: (a,b) => a.title.localeCompare(b.title),
        },
        {
            title: 'Salons',
            dataIndex: 'salons',
            sorter: (a,b) => a.salons.localeCompare(b.salons),
        },
        {
            title: 'Added By',
            dataIndex: 'addedBy',
            sorter: (a,b) => a.addedBy.localeCompare(b.addedBy),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            sorter: (a,b) => a.role.localeCompare(b.role),
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setViewOnly({name: row?.clients});}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.salons}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            title: 'Customer Feedback: Rate Us Every 8 Hours',
            salons: 'All Salons',
            addedBy: 'Super Admin',
            role: 'Admin',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#12',
            title: 'Customer Feedback: Rate Us Every 8 Hours',
            salons: 'Specific Salons',
            addedBy: 'Mohammed Darwish',
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#13',
            title: 'Customer Feedback: Rate Us Every 8 Hours',
            salons: 'All Salons',
            addedBy: 'Super Admin',
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#14',
            title: 'Customer Feedback: Rate Us Every 8 Hours',
            salons: 'All Salons',
            addedBy: 'Salim Al Tajir',
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {TableViewNotification}