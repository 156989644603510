import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewBeauty = ({ setEditItem, setDeleteItem, setVisible, setSwitchText, setVisibleModal }) => {
    const [form] = Form.useForm();
    
  
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };

    const columns = [
        {
            title: 'Salon ID',
            dataIndex: 'index',
            sorter: (a,b) => a.index - b.index,
            width:130
        },
        {
            title: 'Salon Name',
            dataIndex: 'salonName',
            sorter: (a,b) => a.salonName - b.salonName,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            sorter: (a,b) => a.gender.localeCompare(b.gender),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a,b) => a.status - b.status,
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 'Added By',
            dataIndex: 'addedBy',
            sorter: (a,b) => a.addedBy.localeCompare(b.addedBy),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            sorter: (a,b) => a.role.localeCompare(b.role),
        },
        {
            title: 'Time Stamp',
            dataIndex: 'timeStamp',
            sorter: (a,b) => a.timeStamp.localeCompare(b.timeStamp),
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <Switch size='small' defaultChecked onChange={onChange} />
                    <ActionButton
                        title='Image Upload'
                        shape="circle"
                        icon={<Image src='/assets/icons/imgup.png' width={18} preview={false} />}
                        onClick={() => { setVisibleModal(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Edit item'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({name: row?.gender})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={18} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.gender}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            salonName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                Timeless Style
            </Space>,
            gender: 'Male',
            addedBy: 'Super Admin',               
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '2',
            index:'#13',
            salonName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                'Hair Parlour'
            </Space>,
            gender: 'Male',
            addedBy: 'Super Admin',               
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '3',
            index:'#13',
            salonName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                'Classique Curls'
            </Space>,
            gender: 'Female',
            addedBy: 'Super Admin',               
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16',
        },
        {
            key: '4',
            index:'#14',
            salonName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                'Curls & More'
            </Space>,
            gender: 'Female',
            addedBy: 'Super Admin',             
            role: 'Data Entry',
            timeStamp: '11:02PM 9/18/16'
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        showSorterTooltip={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {TableViewBeauty}