import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space } from 'antd'
import { AlertModal, ModuleTopHeading, MyDatepicker, MyInput, MyselectFix, GridViewReservation, ViewReservationDrawer, TableViewEmployees, AddEditEmployeeDrawer, GridViewEmployees, ActivityLogEmployee, MySelect } from '../../components';


const EmployeesPage = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ switchtext, setSwitchText ] = useState(false)
    const [ activitylog, setActivityLog ] = useState(false)
    const [ activityId, setActivityId ] = useState(null)
    const [ changeinput, setChangeInput ] = useState('Employee Name')

    const handleChangeFilter = (e) => {
        setChangeInput(e === 'Employee Name' ? true : false)
    }

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap>
                            <ModuleTopHeading 
                                name='Employees' 
                                onClick={()=>setVisible(true)}
                            />
                            <Flex gap={'small'}>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        onChange={handleChangeFilter}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Employee Name',
                                            },
                                            {
                                                key: 2,
                                                name: 'Contact Number',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    {
                                        changeinput ? (
                                            <MyInput
                                                name='name'
                                                label='Employee Name'
                                                value={form.getFieldValue("name") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : (
                                            <MyInput
                                                name='contactno'
                                                label='Contact Number'
                                                value={form.getFieldValue("contactno") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        )
                                    }
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-gray'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewEmployees {...{ activityId, setActivityId, activitylog, setActivityLog, edititem, setEditItem, switchtext, setSwitchText, deleteitem, setDeleteItem, visible, setVisible }}/>
                            :
                            <GridViewEmployees {...{ activitylog, setActivityLog, edititem, setEditItem, switchtext, setSwitchText, visible, setVisible, deleteitem, setDeleteItem }} />
                        }
                    </Col>
                    
                </Row>
            </Card>
            <AddEditEmployeeDrawer 
                visible={visible}
                edititem={edititem}
                onClose={()=> {setVisible(false);setEditItem(null)}}
            />
            <ActivityLogEmployee 
                visible={activitylog}
                activityId={activityId}
                onClose={()=> {setActivityLog(false);setActivityId(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                switchtext={switchtext}
                onClose={()=>{setDeleteItem(false);setSwitchText(false)}}
            />
        </div>
    )
}

export { EmployeesPage }
