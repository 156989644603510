import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row } from 'antd'
import { GridViewCustomer, TableViewCustomer, AlertModal, ModuleTopHeading, MyDatepicker, MyInput, UploadImageModal, MySelect } from '../../components';


const Customer = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ switchtext, setSwitchText ] = useState(false)
    const [ visibleModal, setVisibleModal ] = useState(false)
    const [ showhide, setShowHide ] = useState('Status')

    const handleShow = (e) => {
        setShowHide(e)
    }

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap>
                            <ModuleTopHeading name='Customers'/>
                            <Flex gap={'small'}>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        onChange={handleShow}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Customer Name',
                                            },
                                            {
                                                key: 2,
                                                name: 'Contact Number',
                                            },
                                            {
                                                key: 3,
                                                name: 'Status',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    {
                                        showhide === 'Contact Number' ? (
                                            <MyInput
                                                name='contactno'
                                                label='Contact Number'
                                                value={form.getFieldValue("contactno") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : showhide === 'Customer Name' ? (
                                            <MyInput
                                                name='name'
                                                label='Customer Name'
                                                value={form.getFieldValue("name") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : (
                                            <MySelect
                                                label= 'Status'
                                                name="status"
                                                value={form.getFieldValue("status") || ''}
                                                onchange={handleShow}
                                                options={[
                                                    {
                                                        key: 1,
                                                        name: 'Active',
                                                    },
                                                    {
                                                        key: 2,
                                                        name: 'Inactive',
                                                    }
                                                ]}
                                            />
                                        )
                                    }
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-gray'
                                    />
                                </Col>
                                <Col lg={2} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewCustomer {...{switchtext, setSwitchText, deleteitem, setDeleteItem, visibleModal, setVisibleModal}}/>
                            :
                            <GridViewCustomer {...{switchtext, setSwitchText, deleteitem, setDeleteItem, visibleModal, setVisibleModal}} />
                        }
                    </Col>
                    
                </Row>
            </Card>
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                switchtext={switchtext}
                onClose={()=>{setDeleteItem(false);setSwitchText(false)}}
            />
            <UploadImageModal 
                visible={visibleModal}
                onClose={()=>setVisibleModal(false)}
            />
        </div>
    )
}

export { Customer }
