import React from 'react'
import { Col, Row } from 'antd'
import { 
  CardComponent, 
  SalonLinechart, 
  ReservationLinechart, 
  ComparisonLinechart, 
  OwnersDonutchart,
  CustomerDonutchart,
  RevenueTrackingChart,
  CustomerLinechart,
} from '../../components'

const Dashboard = () => {
  return (
    <Row gutter={[16,16]}>
        <Col span={24}>
          <CardComponent />
        </Col>
        <Col span={24}>
          <ReservationLinechart />
        </Col>
        <Col span={24}>
          <RevenueTrackingChart />
        </Col>
        <Col span={24}>
          <ComparisonLinechart />
        </Col>        
        <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
          <SalonLinechart />
        </Col>
        <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
          <CustomerLinechart />
        </Col>
        <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
          <OwnersDonutchart />
        </Col>
        <Col lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
          <CustomerDonutchart />
        </Col>
    </Row>
  )
}

export {Dashboard}