import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput } from '../../Forms';

const { Title } = Typography

const ComparisonLinechart = () => {
  const [order, setOrder] = useState(2);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#">Last 30 days</a>, key: 0 },
    { label: <a href="#">Last Quarter</a>, key: 1 },
    { label: <a href="#">Last Year</a>, key: 2 },
  ];

  const onClick = ({ key }) => {
    // key = parseInt(key) + 1;
    setOrder(key);
    // filter(key);
  };

  const chartData = {
    series: [
      {
        name: 'Active Customer’s',
        data: [0, 5, 10, 14, 16, 17, 20, 22, 25],
      },
      {
        name: 'Inactive Customer’s',
        data: [0, 4, 8, 11, 13, 15, 22, 18, 20],
      },
    ],
    options: {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      colors: ['#27AB74','#E0341D'],
      
    },
  };

  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Flex justify='space-between' align='center' wrap gap={10}>
            <ModuleTopHeading name='Active and Inactive Customer’s Comparison' />
            <Form form={form} layout="vertical">
                <Flex justify='end' gap={10}>
                    <Dropdown
                        menu={{ items, onClick }}
                        trigger={['click']}
                        className='margin-top'
                    >
                        <Button className='btnsave radius-8 pad-filter bg-secondary-color text-brand border0'>
                        <Space>
                            {
                              order == 0 ? 'Last 30 days' : 
                              order == 1 ? 'Last Quarter' :
                              order == 2 ? 'Last Year' : ''
                            }  
                            <DownOutlined />
                        </Space>
                        </Button>
                    </Dropdown>
                </Flex>
            </Form>
        </Flex>
        <Form
            form={form}
            layout="vertical"
            className='pt-3'
        >
            <Space size={10} wrap>
                <MyInput
                    name='name'
                    label='City'
                    placeholder='e.g Riyadh'
                    value={form.getFieldValue("name") || ''} 
                    className='w-100 bg-transparent'
                    onChange={(e)=>e.target.value}
                />
                <Button className='brand-bg margin-5 btnsave text-white'>Search</Button>
            </Space>
            <Title level={5} className='m-0 fs-14 fw-500'>Today : 23</Title>
        </Form>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={200}
        />
      </Card>
    </div>
  );
};

export { ComparisonLinechart };
