import React, { useState } from 'react';
import { Card, Dropdown, Form, Flex, Button, Space, Typography, Col, Row } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { DownOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../../PageComponents';
import { MyInput, MySelect, MyselectFix } from '../../Forms';

const { Title } = Typography

const RevenueTrackingChart = () => {
  const [order, setOrder] = useState(0);
  const [form] = Form.useForm();
  const items = [
    { label: <a href="#">Today</a>, key: 0 },
    { label: <a href="#">Last Week</a>, key: 1 },
    { label: <a href="#">Last Month</a>, key: 2 },
    { label: <a href="#">Last Quarter</a>, key: 3 },
    { label: <a href="#">Last Year</a>, key: 4 },
    { label: <a href="#">Next Week</a>, key: 5 },
    { label: <a href="#">Next Month</a>, key: 6 },
  ];

  const onClick = ({ key }) => {
    // key = parseInt(key) + 1;
    setOrder(key);
    // filter(key);
  };

  const chartData = {
    series: [{
      name: 'Online',
      data: [44, 55, 41, 67, 22, 43, 21,23,15,17,34, 45, 54, 98,68]
    }, {
      name: 'In the Venue',
      data: [13, 23, 20, 8, 13, 27, 33, 30, 49, 23, 38, 48, 67, 55,90]
    }, {
      name: 'Credit Card',
      data: [11, 17, 15, 15, 21, 14, 15, 45, 89,32, 39, 47, 67, 89,98]
    }],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar:{
          show:false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
        ],
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        labels: {
          style: {
            colors: '#000',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      responsive: [{
        breakpoint: 480
      }],
      colors: ['#62B2FD','#9BDFC4','#F9DF9B'], 
      legend:{
        markers:{
          shape: "circle"
        }
      }     
    },
  };

  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Flex justify='space-between' align='center' wrap gap={10}>
            <ModuleTopHeading name='Revenue Tracking' />
            <Form form={form} layout="vertical">
                <Flex justify='end' gap={10}>
                    <Dropdown
                        menu={{ items, onClick }}
                        trigger={['click']}
                        className='margin-top'
                    >
                        <Button className='btnsave radius-8 pad-filter bg-secondary-color text-brand border0'>
                        <Space>
                            {
                              order == 0 ? 'Today' : 
                              order == 1 ? 'Last Week' :
                              order == 2 ? 'Last Month' :
                              order == 3 ? 'Last Quarter' :
                              order == 4 ? 'Last Year' :
                              order == 5 ? 'Next Week' :
                              order == 6 ? 'Next Month' :''
                            }  
                            <DownOutlined />
                        </Space>
                        </Button>
                    </Dropdown>
                </Flex>
            </Form>
        </Flex>
        <Form
            form={form}
            layout="vertical"
            className='pt-3'
        >
              <Row gutter={16} align={'middle'}>
                <Col lg={4} md={12} sm={24} xs={24}>
                  <MyInput
                    name='name'
                    label='Salon Name'
                    placeholder='e.g Riyadh'
                    value={form.getFieldValue("name") || ''} 
                    className='w-100 bg-transparent'
                    onChange={(e)=>e.target.value}
                  />
                </Col>
                <Col lg={4} md={12} sm={24} xs={24}>
                    <MySelect
                        label= 'Payment Method'
                        name="paymentmethod" 
                        value={form.getFieldValue("paymentmethod") || ''}
                        options={[
                            {
                              key: 1,
                              name: 'Online'
                            },
                            {
                              key: 2,
                              name: 'Credit card'
                            },
                            {
                              key: 3,
                              name: 'In the venue'
                            },
                        ]}
                    />
                </Col>
                <Col lg={3} md={12} sm={24} xs={24}>
                  <Space size={10}>
                    <Button className='brand-bg margin-5 btnsave text-white'>Search</Button>
                    <Button className='bg-secondary-color pad-filter margin-5 btnsave text-brand'>
                        <Flex gap={5} align='center'>
                            <img src="/assets/icons/file.png" width={16} alt="" />
                            Export
                        </Flex>
                    </Button>
                  </Space>
                </Col>
            </Row>
            
            <Title level={5} className='m-0 fs-14 fw-500'>Today : 23</Title>
        </Form>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={200}
        />
      </Card>
    </div>
  );
};

export { RevenueTrackingChart };
